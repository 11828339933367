<template>
  <div>
    <Main>
      <ErrorWrapper>
        <img :src="require(`@/static/img/pages/maintenance.svg`)" alt="maintenance" />
        <sdHeading class="error-text" as="h3">
          500
        </sdHeading>
        <p>Oops! Something went wrong</p>
        <router-link to="/">
          <sdButton size="default" type="primary" to="/">
            Return Home
          </sdButton>
        </router-link>
      </ErrorWrapper>
    </Main>
  </div>
</template>

<script>
import {Main} from '@/components/shared/styledComponents/Main';
import {ErrorWrapper} from './style';
export default {
  name: 'Maintenance',
  components: {
    Main,
    ErrorWrapper
  },
  data() {
    return {};
  }
};
</script>
